import React from 'react';
import { Link } from 'framework7-react';
import avatarSrc from '../assets/avatar.jpeg';
import './AppstorePageTitle.less';

const AppstorePage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', marginTop: "-80px" }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <h1 className="appstore-page-title">
          <a
           onClick={(e) => {
            e.preventDefault();
            window.open('https://intersend.io?utm_source=interspace_page_cta', '_blank');
          }}>
          <img src="https://intersend-content-s3-bucket.s3.us-east-1.amazonaws.com/Logo_white_version%20%281%29.png" width={'200px'} alt="Logo" className="appstore-page-title-logo"/>
          </a>
          <span className="font-thin font-md">Explore the Apps on Interspace</span>
        </h1>
        <a 
          className="mt-9 mr-20 rounded-lg inline-block rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-indigo-600 focus:outline-none focus:ring active:text-indigo-500 mobile-only-button"
          href="https://app.intersend.io?utm_source=interspace_page_cta"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            window.open('https://app.intersend.io?utm_source=interspace_page_cta', '_blank');
          }}
        >
          Join Intersend Now
        </a>
      </div>
    </div>
  );
};

export default AppstorePage;