import React from 'react';
import { Card, CardContent } from 'framework7-react';
import AppsTableList from './AppsTableList';
import './AppstoreCard.less';

const BuildYourOwnAppCard = ({
  app,
  title = '',
  titleColor = '#fff',
  titleLarge = true,
  titlePosition = 'bottom',
  appColor = '#fff',
  closeButtonColor = '#fff',
  children,
}) => {
  const titleStyle = {
    color: titleColor,
    ...(titleLarge && {
      fontSize: '44px',
      fontWeight: 800,
      lineHeight: 1,
    }),
  };

  return (
    <Card expandable className="appstore-card">
      <CardContent padding={false}>
        <div className={`appstore-card-header appstore-card-header-text-${titlePosition}`}>
          <img src={"https://media.licdn.com/dms/image/D5612AQHiNTixmRFW0g/article-cover_image-shrink_720_1280/0/1682677026278?e=2147483647&v=beta&t=VjK_BP4ZDKY1sG6MUpmZmKEuEtjIjeNtWWOyhOTIAb4"} />
          <div className="appstore-card-header-text">
            <div className="appstore-card-title" style={titleStyle}>Build Your Own App</div>
          </div>
          {app && (
            <AppsTableList style={{ color: appColor }} apps={[app]} />
          )}
        </div>
        <div className="appstore-card-close-button card-opened-fade-in">
          <a href="#" className="link card-close">
            <i style={{ color: closeButtonColor }} className="f7-icons">multiply_circle_fill</i>
          </a>
        </div>
        <div className="appstore-card-content card-content-padding">
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

export default BuildYourOwnAppCard;