import React from 'react';
import { Card, CardContent } from 'framework7-react';
import AppsTableList from './AppsTableList';
import './AppstoreCard.less';

const AppstoreCard = ({
  app,
  title = '',
  titleColor = '#fff',
  titleLarge = true,
  titlePosition = 'bottom',
  appColor = '#fff',
  closeButtonColor = '#fff',
  children,
}) => {
  const titleStyle = {
    color: titleColor,
    ...(titleLarge && {
      fontSize: '44px',
      fontWeight: 800,
      lineHeight: 1,
    }),
  };

  return (
    <Card expandable className="appstore-card">
      <CardContent padding={false}>
        <div className={`appstore-card-header appstore-card-header-text-${titlePosition}`}>
          <img src={app.banner} alt={app.name} />
          {app.kyc === false && (
            <span className="bg-purple-100 text-purple-800 text-xs font-medium px-2.5 py-0.5 rounded-full dark:bg-purple-900 dark:text-purple-300 absolute top-2 right-9">
              No KYC
            </span>
          )}
          <div className="appstore-card-header-text">
            <div className="appstore-card-title" style={titleStyle}>{title}</div>
          </div>
          {app && (
            <AppsTableList style={{ color: appColor }} apps={[app]} cta={true} />
          )}
        </div>
        <div className="appstore-card-close-button card-opened-fade-in">
          <a href="#" className="link card-close">
            <i style={{ color: closeButtonColor }} className="f7-icons">multiply_circle_fill</i>
          </a>
        </div>
        <div className="appstore-card-content card-content-padding">
          {children}
        </div>
      </CardContent>
    </Card>
  );
};

export default AppstoreCard;