import React from 'react';
import { List, ListItem, Button } from 'framework7-react';

import './AppsTableList.less';

const AppsTableList = ({ apps, backText, style, cta }) => {

  console.log('apps', apps);
  console.log('backText', backText);
  console.log('style', style);


  return (
    <List className="apps-table-list" noChevron noHairlines style={ style }>
      {apps.map((app) => (
        <ListItem
          // link={`/app/${app.id}`}
          key={app.id}
          routeProps={{ backText }}
        >
          <div className="apps-table-list-title" slot="title">{app.name}</div>
          <div className="apps-table-list-subtitle item-text" slot="title">{app.description}</div>
          <img loading="lazy" className="apps-table-list-image" slot="media" src={app.logo} alt={app.name} />
          {cta &&
          <div className="apps-table-list-button" slot="inner">
<a
  className="button rounded-lg px-2.5 py-0.2" // Adjusted padding classes
  onClick={() => window.open('https://app.intersend.io?utm_ref=interspace_page', '_blank', 'noopener,noreferrer')}
>
  Get
</a>
              {/* <span>In-App Purchases</span> */}
            </div>
        
        }
        </ListItem>
      ))}
    </List>
  );
};

export default AppsTableList;
